import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex, Spinner } from '@vezeeta/web-components';
import NotAutherizedView from './NotAuthorizedComponents';
import { getRoleBasedAccess, USER_PRIVILEGES } from './RoleBasedAccess';

const UseRoute = ({ ...props }) => {
  const { path, render, localization, isUserLoaded } = props;
  const onRenderPropsChanges = routeProps => {
    let isAccessed = true;
    const roledBasedAccessCatalogue = getRoleBasedAccess();
    switch (routeProps.location.pathname) {
      case '/appointments':
        isAccessed =
          roledBasedAccessCatalogue.appointments_view.canReadAndWrite ===
          USER_PRIVILEGES.AUTHORIZED;
        break;
      case '/working-hours':
        isAccessed =
          roledBasedAccessCatalogue.workingHours_view.canReadAndWrite ===
            USER_PRIVILEGES.AUTHORIZED ||
          roledBasedAccessCatalogue.workingHours_view.canReadOnly === USER_PRIVILEGES.AUTHORIZED;
        break;
      case '/working-hours-ramadan':
        isAccessed =
          roledBasedAccessCatalogue.workingHours_view.canReadAndWrite ===
            USER_PRIVILEGES.AUTHORIZED ||
          roledBasedAccessCatalogue.workingHours_view.canReadOnly === USER_PRIVILEGES.AUTHORIZED;
        break;
      case '/vacations':
        isAccessed =
          roledBasedAccessCatalogue.appointments_view.canReadAndWrite ===
          USER_PRIVILEGES.AUTHORIZED;
        break;
      case '/confirmations':
        isAccessed =
          roledBasedAccessCatalogue.appointments_view.canReadAndWrite ===
          USER_PRIVILEGES.AUTHORIZED;
        break;

      default:
        break;
    }
    if (isUserLoaded && localization) {
      if (isAccessed) return render(routeProps);
      return <NotAutherizedView localization={localization} />;
    }
    return (
      <Flex justifyContent="center" alignItems="center" py={10}>
        <Spinner />
      </Flex>
    );
  };
  return <Route path={path} render={onRenderPropsChanges} />;
};

UseRoute.propTypes = {
  path: PropTypes.string,
  render: PropTypes.func,
  localization: PropTypes.object,
  isUserLoaded: PropTypes.bool,
};
export default UseRoute;
