/* eslint-disable import/prefer-default-export */
import { isFound, Cookie } from '@vezeeta/web-utils';

const getLayoutHeaderTab = (tabs, user, isUserLoaded) => [
  {
    title: tabs.appointments,
    url: '/appointments',
    enable: process.env.REACT_APP_ENABLE_APPOINTMENTS === 'true',
  },
  {
    title: tabs.confirmations,
    url: '/confirmations',
    enable: process.env.REACT_APP_ENABLE_CONFIRMATION === 'true',
  },
  {
    title: tabs.vacations,
    url: '/vacations',
    enable: process.env.REACT_APP_ENABLE_VACATION === 'true',
  },
  {
    title: tabs.workingHours,
    url: '/working-hours',
    enable: process.env.REACT_APP_ENABLE_WORKING_HOURS === 'true',
  },
  {
    title: tabs.ramadanWorkingHours,
    url: '/working-hours-ramadan',
    enable:
      isUserLoaded &&
      window.enableRamadan &&
      window.countriesSupportRamadanSchedule.includes(user.CountryId),
  },
];

export const getFeatureFlags = (user, isUserLoaded) => ({
  enableRamadan:
    (window.enableRamadan || Cookie.get(Cookie.ENABLE_RAMADAN) === 'true') &&
    isUserLoaded &&
    window.countriesSupportRamadanSchedule.includes(user.CountryId),
  enableAddCreditCardInfo:
    window.enableAddCreditCardInfo || Cookie.get(Cookie.ENABLE_SKIP_PAYMENT) === 'true',
});

export const isSupportSingleLanguage = (user, isUserLoaded) => {
  // Get user token
  if (isUserLoaded) {
    // eslint-disable-next-line prefer-destructuring
    const countriesSupportSingleLanguage = window.countriesSupportSingleLanguage;
    return countriesSupportSingleLanguage.includes(user.CountryId);
  }
};

/**
 * Maps through scheduleTab and return first active tab's URL
 */

export const getFirstEnabledTab = scheduleTabs => {
  const activeTabs = scheduleTabs.filter(tab => tab.enable);

  if (isFound(activeTabs)) {
    return activeTabs[0].url;
  }
};

export const getScheduleTabs = (localization, user, isUserLoaded) => {
  const scheduleTabs = [];
  if (localization && localization.Tabs) {
    const tabs = getLayoutHeaderTab(localization.Tabs, user, isUserLoaded);
    tabs.forEach(tab => scheduleTabs.push(tab));
  } else {
    scheduleTabs.push({
      title: '',
      url: '',
      enable: true,
    });
  }
  return scheduleTabs;
};
