import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { FeatureFlagContainer, SetGlobalStyle } from '@vezeeta/web-components';
import EnterpriseLayout from '@vezeeta/enterprise-layout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '@vezeeta/enterprise-modals/lib/Modal.css';
import { MixpanelConsumer } from 'react-mixpanel';
import { language } from '@vezeeta/enterprise-store/lib/types/language';
import '@vezeeta/web-components/lib/shared/Utils.css';
import { accountTypes } from '@vezeeta/enterprise-utils/lib/enum';
import RoleBasedRoute from 'app/RoleBasedAccess/RoleBasedRoute';
import { loadInsuranceProviders } from '@vezeeta/enterprise-store/lib/actions/insurances';
import { loadRelativeTypes } from '@vezeeta/enterprise-store/lib/actions/relativeTypes';
import {
  Appointments,
  WorkingHours,
  WorkingHoursRamadan,
  Confirmations,
  Vacations,
} from 'app/Routes';
import { withTranslation } from 'app/withTranslation/withTranslation';
import { extendContentContainer } from './App.style';
import { getFeatureFlags, isSupportSingleLanguage, getScheduleTabs } from './App.helper';
import './App.css';

const App = ({ ...props }) => {
  /* const { enableRoomView } = window; */
  const { localization, user, isUserLoaded } = props;
  const [mixpanelEventCalled, setMixpanelEventCalled] = useState(false);

  useEffect(() => {
    SetGlobalStyle();
    props.loadRelativeTypes(language);
  }, []);

  useEffect(() => {
    if (user) {
      props.loadInsuranceProviders(user.CountryId);
    }
  }, [user]);

  const scheduleEvent = mixpanel => {
    if (!mixpanelEventCalled) {
      const { EntitySignUpTypeId } = user;
      mixpanel.track('Schedule Management', {
        'Account Type': EntitySignUpTypeId && accountTypes[EntitySignUpTypeId],
      });
      setMixpanelEventCalled(true);
    }
  };

  const supportSingleLanguage = isSupportSingleLanguage(user, isUserLoaded);

  return (
    <FeatureFlagContainer.Provider value={getFeatureFlags(user, isUserLoaded)}>
      <Router>
        <EnterpriseLayout
          onChange={onlanguagechange}
          headerTabs={getScheduleTabs(localization, user, isUserLoaded)}
          activeTab="Schedule"
          supportSingleLanguage={supportSingleLanguage}
          localization={localization}
          extendContentContainer={extendContentContainer}
        >
          <MixpanelConsumer>
            {mixpanel => {
              scheduleEvent(mixpanel);
              return (
                <Switch>
                  <RoleBasedRoute
                    path="/working-hours"
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <WorkingHours
                        {...routeProps}
                        localization={localization.WorkingHours}
                        language={language}
                        languageId={language === 'en' ? 1 : 2}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        isRamadanWH={false}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/working-hours-ramadan"
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <WorkingHoursRamadan
                        {...routeProps}
                        localization={localization.WorkingHours}
                        language={language}
                        languageId={language === 'en' ? 1 : 2}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        isRamadanWH
                      />
                    )}
                  />

                  <RoleBasedRoute
                    path="/appointments"
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <Appointments
                        {...routeProps}
                        localization={localization.Appointments}
                        language={language}
                        languageId={language === 'en' ? 1 : 2}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                      />
                    )}
                  />

                  <RoleBasedRoute
                    path="/confirmations"
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <Confirmations
                        {...routeProps}
                        localization={localization.confirmation}
                        language={language}
                        languageId={language === 'en' ? 1 : 2}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                      />
                    )}
                  />

                  <RoleBasedRoute
                    path="/vacations"
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <Vacations
                        {...routeProps}
                        localization={localization.vacations}
                        language={language}
                        languageId={language === 'en' ? 1 : 2}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                      />
                    )}
                  />
                  <Redirect to="/appointments" />
                </Switch>
              );
            }}
          </MixpanelConsumer>
        </EnterpriseLayout>
      </Router>
    </FeatureFlagContainer.Provider>
  );
};

const mapStateToProps = state => ({
  isUserLoaded: state.user.loaded,
  user: state.user.user,
  relativeTypes: state.relativeTypes.relativeTypes,
  relativeTypesLoadState: state.relativeTypes.relativeTypesLoadState,
  isInsurancesLoaded: state.insurances.isSuccess,
  isInsurancesLoading: state.insurances.isLoading,
  insurances: state.insurances.insurances,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadRelativeTypes,
      loadInsuranceProviders,
    },
    dispatch,
  );

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(App));
