import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

export const Appointments = Loadable({
  loader: () => import('views/appointments'),
  loading: Loading,
});

export const WorkingHours = Loadable({
  loader: () => import('views/workingHoursNew/WorkingHoursContainer'),
  loading: Loading,
});

export const WorkingHoursRamadan = Loadable({
  loader: () => import('views/workingHoursNew/WorkingHoursRamadanContainer'),
  loading: Loading,
});

export const Confirmations = Loadable({
  loader: () => import('views/confirmation'),
  loading: Loading,
});

export const Vacations = Loadable({
  loader: () => import('views/vacation'),
  loading: Loading,
});
